@import 'global';

@font-face {
	font-family: 'UniformRnd';
	src: url('../font/uniformrnd/UniformRnd-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'UniformRnd';
	src: url('../font/uniformrnd/UniformRnd-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'UniformRnd';
	src: url('../font/uniformrnd/UniformRnd-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'BurbankRegular';
	src: url('../font/burbankregular/BurbankBigRegular-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}


.fw-thin{ font-weight: 300; }
.fw-semibold{ font-weight: 600; }
.fw-bold{ font-weight: bold; }
.fw-extrabold{ font-weight: 800; }
.fw-black{ font-weight: 900; }

.fs-uppercase{ text-transform: uppercase; }
.fs-underline{ text-decoration: underline; }
.fs-italic{ font-style: italic; }

.fc-white{	color : $white-color; }

/*génération des taille de polices mobile*/

$mobile_font_size : 35,24,20;

@each $i in $mobile_font_size {

	.fs-#{$i}{
		font-size : get-mobile-vw(1px*$i);
	}
}

/*génération des taille de polices small desktop */
@media screen and (min-width : map-get($grid-breakpoints, lg)) and (max-width : ($desktop_base_size - 1) * 1px ){

	$desktop_font_size : 20;

	@each $i in $desktop_font_size {

		.fs-d-#{$i}{
			font-size : get-desktop-vw(1px*$i);
		}
	}

}

/*génération des taille de polices desktop */
@media screen and (min-width : $desktop_base_size * 1px){

	$desktop_font_size : 20;

	@each $i in $desktop_font_size {

		.fs-d-#{$i}{
			font-size : 1px*$i;
		}
	}

}
